import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { Auth } from 'src/app/classes/auth';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {
  
  projects:any
  notifications: any[];
 
  user:any
  endpointProfileImage:any
  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private authService: AuthService,
     private userService: UserService,
    private router: Router,


  ) {
    this.notifications = [
      {
        icon: 'i-Speach-Bubble-6',
        title: 'New message',
        badge: '3',
        text: 'TESTQQQQQ',
        time: new Date(),
        status: 'primary',
        link: '/chat'
      },

    ];
  }

  ngOnInit() {
    this.endpointProfileImage = this.userService.endpointProfileImage
    const user = Auth.user;
    this.user = user
    Auth.userEmitter.subscribe((res: any) => {
      this.authService.user
      this.user = res
      this.projects = this.user?.projects[0]


    });
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
    // item has child items
    if (!state.sidenavOpen && !state.childnavOpen
      && this.navService.selectedItem.type === 'dropDown') {
      state.sidenavOpen = true;
      setTimeout(() => {
        state.childnavOpen = true;
      }, 50);
    }
    // item has no child items
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
    }
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      console.log('success');
      localStorage.clear();
      window.location.reload();
    });
  }

  profile() {
    this.router.navigate(['/user/profile']);


  }
  

}

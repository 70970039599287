import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { PermissionComponent } from "./views/settings/permission/permission.component";
import { CredentialInterceptor } from "./interceptors/credential.interceptor";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { InventoryComponent } from "./views/inventory/inventory/inventory.component";
import { TimeoutInterceptor } from "./interceptors/timeout-interceptor";

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, SharedModule, HttpClientModule, BrowserAnimationsModule, AppRoutingModule, NgMultiSelectDropDownModule.forRoot()],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor, // เพิ่ม TimeoutInterceptor ใน providers
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

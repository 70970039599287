import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { User } from "src/app/interfaces/user";

@Injectable({
  providedIn: "root"
})
export class AuthService {
 
  constructor(protected http: HttpClient) {
  }

  login(data): Observable<any> {
    return this.http.post(`${environment.api}/login`, data,{ withCredentials: true });
  }

  register(data): Observable<User> {
    return this.http.post<User>(`${environment.api}/register`, data);
  }

  user(): Observable<User> {
    return this.http.get<User>(`${environment.api}/user`);
  }

  logout(): Observable<void> {
    return this.http.post<void>(`${environment.api}/logout`, {});
  }

  updateInfo(data): Observable<User> {
    return this.http.put<User>(`${environment.api}/users/info`, data);
  }
  editUser(data): Observable<User> {
    return this.http.put<User>(`${environment.api}/users/edit`, data);
  }

  updatePassword(data): Observable<User> {
    return this.http.put<User>(`${environment.api}/users/password`, data);
  }

    changePassword(user_id,data): Observable<any> {
    return this.http.put<any>(`${environment.api}/users/change-password/${user_id}`, data);
  }
  
}
